// NOTE: Always convert widht & height to EM (non-relative)
// https://nekocalc.com/px-to-rem-converter

import { classNames } from "@local/utils";
interface Props {
  className?: string;
}
export const BurgerMenuMobile = () => <svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" viewBox="0 0 40 40" aria-hidden="true" data-sentry-element="svg" data-sentry-component="BurgerMenuMobile" data-sentry-source-file="icons.tsx">
    <g stroke="currentColor" strokeLinecap="round" strokeWidth="2" data-sentry-element="g" data-sentry-source-file="icons.tsx">
      <path d="M2.5 11.962H38M2.5 20H38M2.5 28.038H38" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    </g>
  </svg>;
export const BasketIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="1.375em" height="1.313em" viewBox="0 0 22 21" aria-hidden="true" data-sentry-element="svg" data-sentry-component="BasketIcon" data-sentry-source-file="icons.tsx">
    <g fill="currentColor" data-sentry-element="g" data-sentry-source-file="icons.tsx">
      <path d="M8.629 12.572c.505 0 .914.383.914.857v2.285c0 .474-.41.857-.914.857-.505 0-.915-.383-.915-.857V13.43c0-.474.41-.857.915-.857ZM14.419 13.429c0-.474-.41-.857-.914-.857-.505 0-.915.383-.915.857v2.285c0 .474.41.857.915.857.505 0 .914-.383.914-.857V13.43Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
      <path fillRule="evenodd" d="M8.201 1.286c.253-.41.103-.934-.334-1.171-.438-.237-.997-.096-1.25.314L4.18 4.388a.832.832 0 0 0-.085.184h-.343C1.901 4.572.4 5.979.4 7.714c0 .982.48 1.859 1.233 2.435l.813 5.24.544 2.392c.327 1.433 1.575 2.522 3.123 2.724 3.287.429 6.62.429 9.907 0 1.549-.202 2.797-1.29 3.123-2.724l.545-2.392.813-5.24c.752-.576 1.232-1.453 1.232-2.435 0-1.735-1.5-3.142-3.352-3.142h-.343a.832.832 0 0 0-.084-.184L15.516.428c-.253-.41-.812-.55-1.25-.313-.437.237-.586.76-.334 1.17l2.024 3.287H6.178L8.2 1.286Zm10.343 9.568a3.663 3.663 0 0 1-.163.003H3.752l-.162-.003.656 4.232.533 2.337c.165.729.8 1.282 1.586 1.384 3.12.407 6.284.407 9.403 0 .787-.102 1.421-.655 1.587-1.384l.532-2.337.657-4.232ZM2.229 7.714c0-.789.682-1.428 1.523-1.428h14.629c.842 0 1.524.64 1.524 1.428 0 .79-.682 1.429-1.524 1.429H3.752c-.841 0-1.523-.64-1.523-1.429Z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    </g>
  </svg>;
export const CheckMarkIcon = ({
  className
}: Props) => <svg xmlns="http://www.w3.org/2000/svg" width="1.188em" height="1.125em" fill="currentColor" viewBox="0 0 19 18" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="CheckMarkIcon" data-sentry-source-file="icons.tsx">
    <path fill="currentColor" d="M6.357 7.177a.866.866 0 0 0-1.242 0 .837.837 0 0 0 0 1.225l3.639 3.85a.966.966 0 0 0 .62.262c.267 0 .533-.088.622-.35l8.697-10.76c.266-.35.266-.962-.177-1.225-.266-.262-.799-.262-1.154.175l-1.775 2.187C13.99.966 11.682.004 9.375.004 4.494.004.5 3.941.5 8.752S4.494 17.5 9.375 17.5c4.88 0 8.875-3.937 8.875-8.748 0-.787-.09-1.575-.355-2.362-.178-.437-.622-.7-1.065-.612-.444.087-.71.612-.622 1.05.178.612.267 1.312.267 1.924 0 3.85-3.195 6.998-7.1 6.998s-7.1-3.149-7.1-6.998 3.195-6.998 7.1-6.998c1.952 0 3.727.787 5.147 2.187l-5.147 6.386-3.018-3.15Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const CloseIcon = ({
  className
}: Props) => <svg width="1.063em" height="1.063em" viewBox="0 0 17 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="CloseIcon" data-sentry-source-file="icons.tsx">
    <path d="M16 1L1 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path d="M0.999999 1L16 16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const ArrowDownIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="2.188em" height="1.063em" viewBox="0 0 35 17" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-component="ArrowDownIcon" data-sentry-source-file="icons.tsx">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.2225 5.86244C23.5925 6.2324 23.5925 6.83225 23.2225 7.20218L18.1699 12.2548C17.7999 12.6248 17.2001 12.6248 16.8302 12.2548L11.7775 7.20218C11.4075 6.83225 11.4075 6.2324 11.7775 5.86244C12.1474 5.49247 12.7473 5.49247 13.1173 5.86244L17.5 10.2451L21.8828 5.86244C22.2528 5.49247 22.8525 5.49247 23.2225 5.86244Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const PlusIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="1.5em" height="1.5em" aria-hidden="true" data-sentry-element="svg" data-sentry-component="PlusIcon" data-sentry-source-file="icons.tsx">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const MinusIcon = () => <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" width="1.5em" height="1.5em" aria-hidden="true" data-sentry-element="svg" data-sentry-component="MinusIcon" data-sentry-source-file="icons.tsx">
    <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const ShippingTruck = () => <svg xmlns="http://www.w3.org/2000/svg" width="3.125em" height="3.063em" viewBox="0 0 50 49" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-component="ShippingTruck" data-sentry-source-file="icons.tsx">
    <circle cx="25" cy="24.5" r="24.5" fill="#F3F4F6" data-sentry-element="circle" data-sentry-source-file="icons.tsx" />
    <path d="M47.1157 22.762L44.3128 22.2015L42.2573 16.2687C41.7902 14.8672 40.4821 13.9329 38.9873 13.9329H35.0165V11.13C35.0165 9.40161 33.615 8.00015 31.8866 8.00015L8.0151 8C6.28671 8 4.88525 9.40146 4.88525 11.1298V33.74C4.88525 34.3005 5.35241 34.721 5.86624 34.721L8.85605 34.7212V34.9548C8.85605 37.7577 11.1451 40 13.9013 40C16.6575 40 18.9466 37.7109 18.9466 34.9548V34.7212H35.3436V34.9548C35.3436 37.7577 37.6327 40 40.3889 40C43.1918 40 45.4341 37.7109 45.4341 34.9548V34.7212H48.237C48.7975 34.7212 49.218 34.254 49.218 33.7402V25.3314C49.2645 24.1167 48.377 22.9956 47.1157 22.762ZM13.9012 38.0846C12.2195 38.0846 10.818 36.6832 10.818 35.0015C10.818 33.3198 12.2195 31.9183 13.9012 31.9183C15.5829 31.9183 16.9844 33.3198 16.9844 35.0015C16.9844 36.6832 15.6296 38.0846 13.9012 38.0846ZM35.0165 22.0613V15.9416H39.034C39.6414 15.9416 40.2019 16.3154 40.3888 16.9226L42.164 22.0613L35.0165 22.0613ZM40.4355 38.0846C38.7537 38.0846 37.3523 36.6832 37.3523 35.0015C37.3523 33.3198 38.7537 31.9183 40.4355 31.9183C42.1172 31.9183 43.5186 33.3198 43.5186 35.0015C43.5186 36.6832 42.1172 38.0846 40.4355 38.0846Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path d="M14.1353 24.7239L18.9469 26.9194V20.8464L14.1353 18.6509V24.7239Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path d="M19.9274 15.1006L15.5361 17.1093L19.9274 19.1181L24.3653 17.1093L19.9274 15.1006Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path d="M20.9551 26.9197L25.7668 24.7241V18.6511L20.9551 20.8467V26.9197Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const ThankYouBadgeIcon = ({
  className
}: Props) => <svg width="3.375em" height="3.625em" viewBox="0 0 54 58" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="ThankYouBadgeIcon" data-sentry-source-file="icons.tsx">
    <g id="check-icon" filter="url(#filter0_d_31_11230)" data-sentry-element="g" data-sentry-source-file="icons.tsx">
      <path id="Vector" d="M43.8677 12.9005L30.8146 6.79014C28.4986 5.73662 25.5511 5.73662 23.2352 6.79014L10.182 12.9005C7.23454 14.3754 5.55024 17.7467 5.97132 21.1179C7.02401 28.0711 10.3926 41.1347 22.3931 50.827C23.867 51.8806 25.5511 52.3019 27.025 52.3019C28.4986 52.3019 30.183 51.6699 31.4462 50.6163C43.4468 40.924 47.0257 27.8604 47.868 20.9072C48.4996 17.7467 46.8153 14.3754 43.8677 12.9005Z" fill="#05A067" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
      <path id="Vector_2" d="M25.9013 35.8858C25.27 35.8858 24.6386 35.6754 24.2176 35.044L18.5351 28.0987C17.6933 27.2568 17.9037 25.7836 18.7456 25.1522C19.5874 24.3103 21.0607 24.5208 21.6921 25.3627L25.9013 30.4138L34.7408 19.6802C35.5827 18.8383 36.8454 18.6278 37.6873 19.4697C38.5292 20.3116 38.7396 21.5743 37.8978 22.4162L27.3746 35.044C27.1641 35.6754 26.5327 35.8858 25.9013 35.8858Z" fill="white" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    </g>
    <defs data-sentry-element="defs" data-sentry-source-file="icons.tsx">
      <filter id="filter0_d_31_11230" x="0.639596" y="0.732308" width="52.6281" height="56.8373" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB" data-sentry-element="filter" data-sentry-source-file="icons.tsx">
        <feFlood floodOpacity="0" result="BackgroundImageFix" data-sentry-element="feFlood" data-sentry-source-file="icons.tsx" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" data-sentry-element="feColorMatrix" data-sentry-source-file="icons.tsx" />
        <feOffset data-sentry-element="feOffset" data-sentry-source-file="icons.tsx" />
        <feGaussianBlur stdDeviation="2.63385" data-sentry-element="feGaussianBlur" data-sentry-source-file="icons.tsx" />
        <feComposite in2="hardAlpha" operator="out" data-sentry-element="feComposite" data-sentry-source-file="icons.tsx" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" data-sentry-element="feColorMatrix" data-sentry-source-file="icons.tsx" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_11230" data-sentry-element="feBlend" data-sentry-source-file="icons.tsx" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_11230" result="shape" data-sentry-element="feBlend" data-sentry-source-file="icons.tsx" />
      </filter>
    </defs>
  </svg>;
export const TrashIcon = ({
  className
}: Props) => <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="TrashIcon" data-sentry-source-file="icons.tsx">
    <path d="M6.16671 2.5C6.16671 2.22386 6.39057 2 6.66671 2H9.33337C9.60951 2 9.83337 2.22386 9.83337 2.5V3H12.6667C12.9428 3 13.1667 3.22386 13.1667 3.5C13.1667 3.77614 12.9428 4 12.6667 4H3.33337C3.05723 4 2.83337 3.77614 2.83337 3.5C2.83337 3.22386 3.05723 3 3.33337 3H6.16671V2.5Z" fill="#000000" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.15971 5.79652C4.17846 5.62771 4.32115 5.5 4.491 5.5H11.5086C11.6784 5.5 11.8211 5.62771 11.8398 5.79652L11.9732 6.99742C12.2138 9.16207 12.2138 11.3467 11.9732 13.5113L11.9601 13.6296C11.8725 14.4179 11.2615 15.0466 10.476 15.1566C8.83318 15.3866 7.16638 15.3866 5.5236 15.1566C4.73802 15.0466 4.12701 14.4179 4.03941 13.6296L4.02628 13.5113C3.78576 11.3467 3.78576 9.16207 4.02628 6.99742L4.15971 5.79652ZM7.16644 8.1C7.16644 7.82387 6.94258 7.6 6.66644 7.6C6.3903 7.6 6.16644 7.82387 6.16644 8.1V12.7667C6.16644 13.0428 6.3903 13.2667 6.66644 13.2667C6.94258 13.2667 7.16644 13.0428 7.16644 12.7667V8.1ZM9.83311 8.1C9.83311 7.82387 9.60924 7.6 9.33311 7.6C9.05698 7.6 8.83311 7.82387 8.83311 8.1V12.7667C8.83311 13.0428 9.05698 13.2667 9.33311 13.2667C9.60924 13.2667 9.83311 13.0428 9.83311 12.7667V8.1Z" fill="#000000" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const SearchIcon = ({
  className
}: Props) => <svg width="1em" height="1em" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="SearchIcon" data-sentry-source-file="icons.tsx">
    <path fill="currentColor" fillRule="evenodd" stroke="currentColor" strokeWidth=".804" d="M21.163 23.114c-4.874 3.904-12.009 3.597-16.527-.922-4.848-4.848-4.848-12.708 0-17.556 4.848-4.848 12.708-4.848 17.556 0 4.519 4.518 4.826 11.653.922 16.527l9.482 9.482a1.38 1.38 0 0 1-1.95 1.951l-9.483-9.482ZM6.587 20.242c-3.771-3.771-3.771-9.885 0-13.655A9.655 9.655 0 0 1 20.25 20.233l-.009.009-.008.008c-3.771 3.762-9.878 3.76-13.646-.008Z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const FolderIcon = ({
  className
}: Props) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="FolderIcon" data-sentry-source-file="icons.tsx">
    <path d="M19,3H12.472a1.019,1.019,0,0,1-.447-.1L8.869,1.316A3.014,3.014,0,0,0,7.528,1H5A5.006,5.006,0,0,0,0,6V18a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V8A5.006,5.006,0,0,0,19,3ZM5,3H7.528a1.019,1.019,0,0,1,.447.1l3.156,1.579A3.014,3.014,0,0,0,12.472,5H19a3,3,0,0,1,2.779,1.882L2,6.994V6A3,3,0,0,1,5,3ZM19,21H5a3,3,0,0,1-3-3V8.994l20-.113V18A3,3,0,0,1,19,21Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const FileIcon = ({
  className
}: Props) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="FileIcon" data-sentry-source-file="icons.tsx">
    <path d="M19.949,5.536,16.465,2.05A6.958,6.958,0,0,0,11.515,0H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V10.485A6.951,6.951,0,0,0,19.949,5.536ZM18.535,6.95A4.983,4.983,0,0,1,19.316,8H15a1,1,0,0,1-1-1V2.684a5.01,5.01,0,0,1,1.051.78ZM20,19a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V5A3,3,0,0,1,7,2h4.515c.164,0,.323.032.485.047V7a3,3,0,0,0,3,3h4.953c.015.162.047.32.047.485Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const DownloadIcon = ({
  className
}: Props) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="DownloadIcon" data-sentry-source-file="icons.tsx">
    <path d="M9.878,18.122a3,3,0,0,0,4.244,0l3.211-3.211A1,1,0,0,0,15.919,13.5l-2.926,2.927L13,1a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1l-.009,15.408L8.081,13.5a1,1,0,0,0-1.414,1.415Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
    <path d="M23,16h0a1,1,0,0,0-1,1v4a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V17a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v4a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V17A1,1,0,0,0,23,16Z" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const ArrowLeftIcon = ({
  className
}: Props) => <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 73 59" className={className} aria-hidden="true" data-sentry-element="svg" data-sentry-component="ArrowLeftIcon" data-sentry-source-file="icons.tsx">
    <path d="M37.0366 7.90828L21.3142 23.6316L72.101 23.6316L72.101 34.8162L21.8071 34.8162L37.533 50.5403L29.6236 58.4487L1.01927e-05 29.1279L29.1281 2.29461e-05L37.0366 7.90828Z" fill="#E8E8E8" data-sentry-element="path" data-sentry-source-file="icons.tsx" />
  </svg>;
export const ArrowRightIcon = ({
  className
}: Props) => <ArrowLeftIcon className={classNames("rotate-180", className ? className : "")} data-sentry-element="ArrowLeftIcon" data-sentry-component="ArrowRightIcon" data-sentry-source-file="icons.tsx" />;